import React, { useEffect, useState, useMemo } from "react"
import Swal from "sweetalert2";


export const SwalContext = React.createContext(null);
export const SwalProvider = ({ children }) => {
  
  const errror = (title, msg) => {
    Swal.fire({
      title: title,
      text: msg,
      icon: 'error',
      confirmButtonColor: '#2675cf',
      background: 'linear-gradient(#010203, #122747)'
    })
  }

  const info = (title, msg, icon = 'info') => {
    Swal.fire({
      title: title,
      text: msg,
      icon: icon,
      confirmButtonColor: '#2675cf',
      background: 'linear-gradient(#010203, #122747)'
    })
  }

  const confirm = (
    title = 'are you sure?', 
    msg = `You won't be able to revert this!`, 
    icon = 'warning', 
    onOkCallback = (data = false) => { return data }) => 
    {
      Swal.fire({
        title: title,
        text: msg,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: '#2675cf',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'linear-gradient(#010203, #122747)'
      }).then((result) => {
        if (result.isConfirmed) {
          onOkCallback(true)
        }
      })
  }

  const values = useMemo(() => ({
    confirm,
    errror,
    info
  }), [])

  return (
    <SwalContext.Provider value={values}>
      {children}
    </SwalContext.Provider>
  )
}

export default function useSwal(){
  const context = React.useContext(SwalContext);
  if (context === undefined) {
    throw new Error('useSwal hook must be with a useSwalProvider');
  }
  return context;
}