import React from 'react'
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { HOME_ROUTE, STAKING_ROUTE } from './constants/routers';
import Staking from './pages/Stake.page';

export default function AppRoutes() {
  return (
    <Routes>
      <Route path={HOME_ROUTE} element={<Navigate to={STAKING_ROUTE} />} />
      <Route path={STAKING_ROUTE} element={<Staking />} />
    </Routes>
  )
}
