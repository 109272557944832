import React, { useState } from 'react'
import copy from 'copy-to-clipboard'

export default function useClipboard(){
  const [isCopied, setIsCopied] = useState("Copy");
  const clip = (text = "") => {
    if(typeof text === 'undefined'){
      throw new Error("Undefined type!!");
    }else{
      copy(text.toString());
      setIsCopied("Copied!")
    }
  }
  return [clip, isCopied]
}