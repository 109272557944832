import { Container } from 'react-bootstrap';
import React from 'react';
import Stakes from '../components/Stakes';

const Staking = () => {
  return (
    <Container style={{ marginTop: 100 }}>
      <Stakes/>
    </Container>
  );
};

export default Staking;
