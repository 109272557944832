import React, { useEffect, useState, useMemo } from "react"
import { useWeb3React } from '@web3-react/core'
import { injected } from "../connectors/injected"
import { walletconnect } from "../connectors/walletconnect"
import { metamask } from "../connectors/metamask"
import { walletlink } from "../connectors/walletlink"
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { WCCHAIN } from "../utils/web3.chains"
import Web3 from "web3"

export const Web3Context = React.createContext(null);

let web3Modal;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: WCCHAIN
    }
  }
}

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions,
    theme: {
      background: "rgb(39, 49, 56)",
      main: "rgb(199, 199, 199)",
      secondary: "rgb(136, 136, 136)",
      border: "rgba(195, 195, 195, 0.14)",
      hover: "rgb(16, 26, 32)"
    }
  });
}

export const Web3Provider = ({ children }) => {
  const { active, account, library, connector, activate, deactivate } = useWeb3React()

  const [isConnected, setIsConnected] = React.useState(false)
  const [wallet, setWallet] = React.useState('');
  const [provider, setProvider] = React.useState(null);
  const [address, setAddress] = React.useState('');

  const connectToInjectedProviders = () => new Promise((resolve, reject) => {
    try {
      activate(injected).then((data) => {
        setIsConnected(true)
        setWallet('injected')
        setAddress(account)
        setProvider(library);
        resolve(true)
      })
      .catch((err) => {
        console.log(err, '@injected errors')
        resolve(false)
      })
    } catch (err) {
      reject(err)
    }
  });

  useEffect(() => {
    if(account !== undefined){
      setAddress(account)
    }
  }, [account, connectToInjectedProviders])

  const connectWeb3Modal = () => new Promise(async(resolve, reject) => {
    try{
      const _provider = await web3Modal.connect();
      console.log(_provider,'@prov')
      if(_provider){
        const web3 = new Web3(_provider);
        const account = await web3.eth.getAccounts();
        resolve({
          _account: account[0],
          _provider
        });
      }
    }catch(err){
      console.log(err, '@error?')
      reject(false)
    }
  });

  const connect =  async () => {
    return await connectWeb3Modal().then((_d) => {
      setAddress(_d._account);
      setProvider(_d._provider);
      return true
    })
    .catch((err) => {
      console.log(err, '@err?')
    })
  }

  let temp = true;
  useEffect(() => {
    if (typeof provider !== 'undefined' && provider !== null) {
      if (provider.on) {
        const handleAccountsChanged = (accountCallback = []) => {
          temp = true;
          try {
            if (accountCallback) {
              if (temp) {
                console.info('@fromAccountChanged');
                setProvider(provider);
                setAddress(accountCallback[0]);
              } else {
                console.info('@account changed with:', accountCallback[0]);
                setProvider(provider);
                setAddress(accountCallback[0]);
              }
              temp = true;
            }
          } catch (e) {
            console.log(e, '@error')
          }
        };

        //https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
        const handleChainChanged = (_hexChainId = '') => {
          console.log('on chain changed');
          window.location.reload();
        };

        provider.on('accountsChanged', handleAccountsChanged);
        provider.on('chainChanged', handleChainChanged);
      }
    }

    return async () => {
      console.info('@on cleaning handler');

      setProvider(null)
    };
  }, [provider]);

  const connectToMetamask = () => new Promise((resolve, reject) => {
    try{
      activate(metamask).then((data) => {
        setIsConnected(true)
        setWallet('metamask')
        setAddress(account)
        resolve(true)
      })
      .catch((err) => {
        console.log(err, '@walletconnect errors')
        resolve(false)
      })
    }catch(err){
      reject(err)
    }
  })

  const connectToWalletConnect = () => {
    try{
      console.log('wallet connect')
      setTimeout(() => {
        activate(walletconnect)
        setIsConnected(true)
        setWallet('walletconnect')
      }, 500)
    }catch(err){
      console.log(err, '@err conn wallet')
    }
  }

  const connectToWalletLink = () => new Promise((resolve, reject) => {
    try{
      activate(walletlink).then((data) => {
        setIsConnected(true)
        setWallet('walletlink')
        resolve(true)
      })
      .catch((err) => {
        console.log(err, '@walletconnect errors')
        resolve(false)
      })
    }catch(err){
      reject(err)
    }
  })

  const disconnectToInjectedProviders = () => new Promise(async(resolve, reject) => {
    try {
      await web3Modal.clearCachedProvider();
      setIsConnected(false);
      deactivate();
      setProvider(null);
      setAddress('');
      console.log('here?')
      resolve(true)
    } catch (err) {
      reject(err)
    }
  });

  const init = async () => {
    await connectToInjectedProviders()
  }

  const cleanup = async () => {
    await disconnectToInjectedProviders()
  }

  useEffect(() => {
    init()
    return () => {
      cleanup()
    }
  }, [])
  
  
  const values = useMemo(() => ({
    isConnected,
    disconnectToInjectedProviders,
    connectToInjectedProviders,
    connectToMetamask,
    connectToWalletConnect,
    connectToWalletLink,
    connectWeb3Modal,
    active,
    account,
    library,
    connector,
    connect,
    address
  }), [
    account,
    address
  ])

  return (
    <Web3Context.Provider value={values}>
      {children}
    </Web3Context.Provider>
  )
}

export default function useWeb3(){
  const context = React.useContext(Web3Context);
  if (context === undefined) {
    throw new Error('useWeb3 hook must be with a useWeb3Provider');
  }
  return context;
}