export const toSmallUnit = (price, decimal) => {
  return price / Math.pow(10, decimal);
};

export const toNormalUnit = (price, decimal) => {
  return price * Math.pow(10, decimal);
};

export const calculateApy = (
  maxStakeAmount,
  maxParticipant,
  rewardTotal,
  periodDay
) => {
  return (
    (maxParticipant * maxStakeAmount / rewardTotal)
  );
};

export const calculatePeriod = (interval, period, onlyNumber = false) => {
  let _i = 0, _p = 0
  let _s = 3600;
  let _ds = 25
  if(typeof interval !== 'number' || typeof period !== 'number'){
    _i = +interval
    _p = +period
  }
  _i = interval;
  _p = period;
  const _t = _i * _p;
  const d = Math.floor(_t / (_s*_ds));
  if (onlyNumber)
    return d > 1 ? d : 1;
  else 
    return d > 1 ? d + (d === 1 ? ' day, ' : ' days ') : 1 + ' day';
}