import React, { useState } from 'react';
import {
  Navbar,
  Container,
  Button,
  Modal,
  Row,
  Col
} from 'react-bootstrap';
import classes from '../styles/Staking.module.css';
import WalletProviders from './WalletProviders';

import Metamask from '../logos/metamask.svg';
import WalletConnect from '../logos/walletconnect.svg';
import WalletLink from '../logos/walletlink.svg';
import Injected from '../logos/injected.svg';
import Brand from '../logos/brand.svg';
import useWeb3 from '../hooks/useWeb3';
import useSwal from '../hooks/useSwal';

const Navmenu = (props) => {
  const [poolStatus, setPoolStatus] = useState('default');
  const [openModal, setOpenModal] = useState(false);
  const [isConnect, setIsConnect] = useState(false);

  const web3Hook = useWeb3();
  const swalHook = useSwal();

  const { 
    disconnectToInjectedProviders,
    address,
    connect
  } = web3Hook;

  const {
    error,
    confirm,
    info
  } = swalHook

  const changePool = (val) => {
    if (val === poolStatus) {
    } else {
      setPoolStatus(val);
      props.changePage();
    }
  };

  React.useEffect(() => {
    console.log(address, '@accountEffect')
    if(address === ''){
      setIsConnect(false)
    }
  }, [
    address
  ]);

  const onClickConnectWallet = async () => {
    // setOpenModal(true);
    const _connect = await connect()
    console.log(_connect, '@connect?')
    if(_connect){
      setIsConnect(true)
    }
  }

  const disconnectWallet = async () => {
    try{
      confirm(
        'Disconnect Wallet',
        '',
        'warning',
        async (_confirm) => {
          if(_confirm){
            const _disconnect = await disconnectToInjectedProviders();
            if(_disconnect){
              setIsConnect(false);
              console.log(address, '@address dis')
              info('Disconnected', `You've been disconnected!`, 'success');
              
            }
          }
        }
      )
    }catch(err){
      console.log(err)
      error('Some error occured', err.message)
    }
  }

  const onWalletConnect = (address = "") => {
    if(address !== ""){
      setIsConnect(true)
      setOpenModal(false)
    }
  }

  console.log(address, '@addressss')

  return (
    <>
      <Navbar
        sticky="top"
        variant="navbar-dark"
        className={classes.customNavbar}
        expand={false}
      >
        <Container>
          <img
            src={Brand}
            alt="logo"
            style={{ width: 'auto', height: '50px', padding: '8px' }}
          />
          <Row>
            <Col>
              <Button
                placement="end"
                onClick={isConnect ? disconnectWallet : onClickConnectWallet}
                style={{
                  background: `linear-gradient(#010203, #122747)`,
                }}
                className={!isConnect ? 'connect-btn' : 'disconnect-btn'}
              >
                {isConnect ? `Disconnect Wallet` : 'Connect Wallet'}
              </Button>
            </Col>
          </Row>
        </Container>
      </Navbar>
      <ConnectModal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        onConnect={(
          data = {
            active: false,
            account: '',
            library: null,
            connector: null,
          }
        ) => {
          onWalletConnect(data);
        }}
      />
    </>
  );
};

const ConnectModal = ({open, handleClose, onConnect}, props) => {
  const web3Hook = useWeb3();
  const {
    connectToInjectedProviders,
    connectToMetamask,
    connectToWalletConnect,
    connectToWalletLink,
    connectWeb3Modal,
    active,
    account,
    library,
    connector,
    address
  } = web3Hook;

  const walletProviders = [
    {
      name: 'Metamask',
      logo: Metamask,
      id: 1,
      provider: 'metamask'
    },
    {
      name: 'Wallet Connect',
      logo: WalletConnect,
      id: 2,
      provider: 'walletconnect'
    },
    {
      name: 'Wallet Link',
      logo: WalletLink,
      id: 3,
      provider: 'walletlink'
    },
    {
      name: 'Injected Wallet',
      logo: Injected,
      id: 4,
      provider: 'injected'
    }
  ]

  const connect = async (provider) => {
    try{
      // switch(provider){
      //   case 'metamask':
      //     await connectToMetamask()
      //   break;
      //   case 'walletconnect':
      //     await connectToWalletConnect()
      //   break;
      //   case 'injected':
      //     await connectToInjectedProviders()
      //   break;
      //   case 'walletlink':
      //     await connectToWalletLink()
      //   break;
      //   default:
      //     await connectToInjectedProviders()
      // }
      await connectWeb3Modal()
    }catch(err){
      console.log(err, '@error')
    }
  }

  React.useEffect(() => {
    if(active === true){
      onConnect(address)
    }
  }, [
    address
  ])

  return (
    <Modal
      {...props}
      show={open}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          backgroundColor: '#000'
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Connect Wallet
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: '#000'
        }}
      >
        {
          walletProviders.map((x, i) => (
            <div key={i}>
              <WalletProviders
                logo={x.logo}
                providerName={x.name}
                onClick={() => {connect(x.provider)}}
                key={x.id}
              />
            </div>
          ))
        }
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: '#000'
        }}
      >
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default Navmenu;
