import React from 'react';
import Web3 from 'web3';
import Navmenu from './components/Navmenu';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Web3Provider } from './hooks/useWeb3';
import { Web3ReactProvider } from '@web3-react/core';
import { SwalProvider } from './hooks/useSwal';
import AppRoutes from './Routes';

const App = () => {
  const getLibrary = (provider) => {
    const library = new Web3(provider);
    return library;
  }

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3Provider>
        <SwalProvider>
          <Navmenu />
          <AppRoutes />
        </SwalProvider>
      </Web3Provider>
    </Web3ReactProvider>
  );
};

export default App;
